<template>
  <div class="-info">
    <div class="c_main -pad-btm">
			<div class="inner">
				<nav class="c_pnkz">
					<ul class="c_pnkz_list">
						<li><a class="c_pointer" @click="$router.push({ name: 'Home'})">ホーム</a></li>
						<li><a class="c_pointer" @click="$router.push({ name: 'Info'})">JC3からのお知らせ</a></li>
						<li>{{ info.title }}</li>
					</ul>
				</nav>
				<section class="info">
					<p class="info_ttl">JC3からのお知らせ</p>
					<article class="single">
						<header class="single_hd">
							<div class="single_hd_info"><time>{{ info.start_datetime | formatDateDot }}</time></div>
							<h1 class="single_hd_ttl">{{ info.title }}</h1>
						</header>
						<div class="single_body">
							<p style="white-space:pre-wrap; word-wrap:break-word;">{{ info.content }}</p>
						</div>
						<div class="single_ft">
							<a class="c_btn" @click="$router.push({ name: 'Info'})">一覧に戻る</a>
						</div>
					</article>
				</section>
			</div>
		</div>
  </div>
</template>

<script>
export default {
	props: ['id'],
	data() {
		return {
			info: {
				title: '',
				start_datetime: '',
				content: ''
			},
		}
	},
	created() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true

      this.$authAxios.get(`/api/info/${this.id}`)
      .then((response) => {
					this.info = response.data
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false
      })
		},
  }
}
</script>

<style scoped>
@import "~@/css/info.css";
</style>